import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-editcost-center',
  templateUrl: './cost-center-dialog.html',
})
export class CostCenterDailogComponent {
  costCentreForm!: FormGroup;
  isEditMode: boolean = false;
  accountList: any[] = [];
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  isNumericInputValid: boolean = true;

  private unsubscribe: Subject<void> = new Subject();

  constructor(
    public matDialog: MatDialogRef<CostCenterDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private costService: CostCenterService,
    private globalService: GlobalService,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();

    this.loadAccountList().subscribe(() => {
      if (this.isEditMode) {
        this.costService
          .getCostListById(this.data.element.cost_centerID)
          .subscribe((res: any) => {
            const selectedAccount = this.accountList.find(
              (account) => account.name === this.data.element.account.name
            );

            this.costCentreForm.patchValue({
              code: res.data.code,
              centerName: res.data.centerName,
              centerLeader: res.data.centerLeader,
              marginRate: res.data.marginRate,
              cost_centerID: res.data.cost_centerID,
              accountID: selectedAccount?.accountID,
            });
          });
      }
    });

    this.costCentreForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.costCentreForm.invalid || !this.costCentreForm.dirty;
    });

    this.isButtonDisabled = true;
  }

  initializeForm(): void {
    this.costCentreForm = this.formBuilder.group({
      code: ['', [Validators.required, whitespaceValidator()]],
      centerName: ['', [Validators.required, whitespaceValidator()]],
      centerLeader: ['', [Validators.required, whitespaceValidator()]],
      marginRate: ['', [Validators.required, this.greaterThanZeroValidator()]],
      accountID: ['', Validators.required],
    });
    if (this.isEditMode) {
      this.costCentreForm.addControl(
        'cost_centerID',
        this.formBuilder.control('')
      );
    }
  }

  greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value > 0 ? null : { greaterThanZero: true };
    };
  }

  loadAccountList(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accountList = response.data.records;
        })
      );
  }

  onSubmit(): void {
    if (this.costCentreForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;
      const costData = { ...this.costCentreForm.value };

      // If the code is empty, set it to an empty string
      if (!costData.code.trim()) {
        costData.code = ''; // Setting code to an empty string
      }

      if (this.isEditMode) {
        delete costData.accountID;

        this.costService
          .updateCost(costData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              this.isSubmitting = false;
              console.error('Error updating cost center:', error);
            },
          });
      } else {
        this.costService
          .createCost(costData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              this.isSubmitting = false;
              console.error('Error creating cost center:', error);
            },
          });
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  handleNumericInputValidityChange(validity: boolean) {
    this.isNumericInputValid = validity;
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
